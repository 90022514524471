import { AuthService } from '@lib/api/auth/AuthService';
import { setAuthToken } from '@lib/browser-storage';
import { getCurrentUserData } from '@lib/api/user';
import { setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { getFreeSpinsQty } from '../bonus/getFreeSpinsQty';
import { SuccessToast } from '@components/common/global-settings';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import toast from 'react-hot-toast';

export const signUp = async (
	params: {
		email?: string;
		password?: string;
		phone?: string;
		ref?: string;
		tgId?: number;
		partnerId?: string;
		currency?: string;
		timezone?: string;
		locale: string;
		analyticalSpaceSessionId?: string;
		isApp?: boolean;
	},
	successFunc?: (isFirstLogin: boolean) => void,
	setFieldError?: (field: string, error: string) => void
) => {
	setIsAuthPending(true);

	try {
		const res = await AuthService.signUp(params);

		if (res && res.status === 201) {
			await setAuthToken(res.data.accessToken);
			await getCurrentUserData(() => successFunc && successFunc(res?.data?.isFirstLogin));
			await getFreeSpinsQty();

			!res?.data?.isFirstLogin
				? sendAnalyticsHandler(AnalyticsEventType?.registration_avtorizaition)
				: sendAnalyticsHandler(AnalyticsEventType?.registration_success);

			res?.data?.isFirstLogin &&
				toast((t) => SuccessToast({ t: t, text: 'check_email_for_verification' }));
		}
	} catch (error: any) {
		if (
			(error?.response?.status === 400 &&
				error?.response?.data?.message === 'User already exist') ||
			error?.response?.status === 500
		) {
			setFieldError && setFieldError('phone', 'already_used_phone');
			setFieldError && setFieldError('email', 'already_used_email');
		}

		if (
			error?.response?.status === 422 &&
			error?.response?.data?.message[0]?.constraints?.isEmail === 'email must be an email'
		) {
			setFieldError && setFieldError('email', 'invalid_email');
		}

		if (
			error?.response?.status === 422 &&
			error?.response?.data?.message[0]?.constraints?.isPhoneNumber ===
				'phone must be a valid phone number'
		) {
			setFieldError && setFieldError('phone', 'invalid_phone');
		}

		sendAnalyticsHandler(AnalyticsEventType?.registration_error, {
			registration_error: error?.response?.data?.message?.toString(),
			registration_error_mail: params?.email || '',
			registration_error_phone: params?.phone || ''
		});

		setIsAuthPending(false);
	}
};
