export enum PasswordComplexity {
	empty = 'empty',
	easy = 'easy',
	medium = 'medium',
	hard = 'hard'
}

export enum EmailStatus {
	error = 'error',
	success = 'success'
}

export enum UrlQueries {
	ref = 'ref',
	tgId = 'tgId',
	partnerId = 'partnerId',
	session_id = 'session_id',
	accessToken = 'accessToken',
	platform = 'platform',
	verifyEmailCode = 'verifyEmailCode',
	resultPayment = 'resultPayment',

	token = 'token',
	email = 'email',
	password = 'password',
	isFirstLogin = 'isFirstLogin',

	code = 'c',
	link = 'link'
}

export enum PlatfromQueryE {
	telegram = 'telegram',
	app = 'app'
}

export enum SessionStorageParams {
	apkBanner = 'apkBanner',
	returnGame = 'returnGame',
	wageringGamesList = 'wageringGamesList',
	games_ = 'games_'
}

export type SetFloatNumber = (str: string) => string;
export type ValidateEmail = (email: string) => EmailStatus;
export type TrimString = (str: string, symbolsLimit: number) => string;
export type InclineWord = (
	cases: Array<string>,
	qty: number,
	core?: Array<number>
) => string;
export type GetStringQueryFromUrl = (query: UrlQueries) => string | null;
export type DeleteStringQueryFromUrl = (query: string) => void;
